import { COLLECTION_NAME } from './consts';
import faunadb from 'faunadb';
const q: any = faunadb.query;
const clientSecret: any = process.env.GATSBY_FAUNA_KEY;
let createUser: Function = () => {};

interface QueryResponse {
  data: User;
  error: any;
  ts: any;
  id: string;
}

interface User {
  id?: string;
  username: string;
  email: string;
  ethAddress: string;
  referralCode: string;
  referredBy: string;
  referredTo: string[];
  whitelist: boolean;
  score: number;
}

if (typeof window !== `undefined`) {
  const client = new faunadb.Client({
    secret: clientSecret,
  });

  createUser = async (userObj: User) => {
    try {
      const queryResponse: QueryResponse = await client.query(
        q.Create(q.Collection(COLLECTION_NAME), {
          data: {
            email: userObj?.email,
            username: userObj?.username,
            ethAddress: userObj?.ethAddress,
            referralCode: userObj?.referralCode,
            referredBy: userObj?.referredBy,
            referredTo: userObj?.referredTo,
            whitelist: userObj?.whitelist,
            score: userObj?.referredTo?.length,
          },
        })
      );
      const userInfo = {
        username: queryResponse?.data.username,
        email: queryResponse?.data.email,
        ethAddress: queryResponse?.data.ethAddress,
        referralCode: queryResponse?.data.referralCode,
        referredBy: queryResponse?.data.referredBy,
        referredTo: queryResponse?.data.referredTo,
        whitelist: queryResponse?.data.whitelist,
        score: queryResponse?.data.referredTo.length,
        _ts: queryResponse?.ts,
        _id: queryResponse?.id,
      };
      return userInfo;
    } catch (err) {
      console.log(err);
    }
  };
}

export default createUser;
