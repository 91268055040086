import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import media from '../styles/media';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import One from '../sections/one';
import Two from '../sections/two';
import Three from '../sections/three';
import Four from '../sections/four';
import Five from '../sections/five';
import Six from '../sections/six';
import Seven from '../sections/seven';
import Eight from '../sections/eight';
import createPersistedState from 'use-persisted-state';
import Cookies from 'universal-cookie';
import createUser from '../lib/utils/createUser';
import updateCounts from '../lib/utils/updateCounts';
import crumplebgMobile from '../assets/home/background-mobile.jpg';
import crumplebgDesktop from '../assets/home/background-desktop2.jpg';
import brandHeroLogo from '../assets/home/logo-v2.svg';
import {
  PASSWORD_COOKIE_KEY_2,
  REFERRAL_COOKIE_KEY_2,
  AUTHENTICATED_COOKIE_KEY_2,
  DEFAULT_NO_REFERRER,
} from '../lib/utils/consts';

const IndexPage = ({ location, data }: { location: any; data: any }) => {
  const currentCounts = data?.allCounts?.nodes[0];
  const cookies = new Cookies();
  const params = new URLSearchParams(location.search);
  const refCode = params.get(REFERRAL_COOKIE_KEY_2);
  const persistLogin = createPersistedState(PASSWORD_COOKIE_KEY_2);
  const persistReferral = createPersistedState(REFERRAL_COOKIE_KEY_2);
  const persistAuthenticated = createPersistedState(AUTHENTICATED_COOKIE_KEY_2);
  const [storedPassword, setStoredPassword] = persistLogin('');
  const [referralCode, setReferralCode] = persistReferral('');
  const [authenticated, setAuthenticated] = persistAuthenticated(false);
  const [auth, setAuth] = useState(false);
  const [name, setName] = useState('default');
  const [currCounts, setCurrCounts] = useState(currentCounts);
  const [email, setEmail] = useState('');
  const [refcode, setRefcode] = useState('');
  const [password, ___] = useState('');
  const [ethAddress, __] = useState('');
  const [referredBy, _] = useState(refCode);
  const [submitClicked, setSubmitClicked] = useState(false);

  const clearLocalData = () => {
    localStorage.clear();
    cookies.remove(REFERRAL_COOKIE_KEY_2);
    cookies.remove(PASSWORD_COOKIE_KEY_2);
    cookies.remove(AUTHENTICATED_COOKIE_KEY_2);
  };

  const setLocalData = (ref: string) => {
    setReferralCode(ref);
    setStoredPassword(ref);
    cookies.set(REFERRAL_COOKIE_KEY_2, ref, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
    });
    cookies.set(PASSWORD_COOKIE_KEY_2, ref, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
    });
    cookies.set(AUTHENTICATED_COOKIE_KEY_2, authenticated, {
      path: '/',
      maxAge: 60 * 60 * 24 * 365,
    });
  };

  const handleData = async (ref: string) => {
    const dataObject = {
      username: name,
      email: email,
      ethAddress: ethAddress,
      referralCode: ref,
      referredBy: referredBy ? referredBy : DEFAULT_NO_REFERRER,
      referredTo: [],
      whitelist: false,
      score: 0,
    };
    const countsObject = {
      gabe: currCounts.gabe,
      keith: currCounts.keith,
      michael: currCounts.michael,
      rossi: currCounts.rossi,
      nyc: currCounts.nyc,
      craniums: currCounts.craniums,
      alex: currCounts.alex,
      playboy: currCounts.playboy,
      karsen: currCounts.karsen,
      z: currCounts.z,
      fox: currCounts.fox,
      password: currCounts.password,
      matt: currCounts.matt,
      nolimits: currCounts.nolimits,
      house: currCounts.house,
      artbasel: currCounts.artbasel,
      basel: currCounts.basel,
      miami: currCounts.miami,
      zhovak: currCounts.zhovak,
      swans: currCounts.swans,
      beer: currCounts.beer,
      jpeg: currCounts.jpeg,
      midnight: currCounts.midnight,
      nosleep: currCounts.nosleep,
      back2back: currCounts.back2back,
      animalz: currCounts.animalz,
    };
    await createUser(dataObject);
    await updateCounts(countsObject);
    setSubmitClicked(true);
  };

  const handleSetRefcode = async (e: any) => {
    clearLocalData();
    e.preventDefault();
    if (refcode.length === 0 || email.length === 0) {
      return;
    }
    let currentRefs = [
      { ref: 'PARTYWITHGABE', count: currCounts.gabe },
      { ref: 'PARTYWITHKEITH', count: currCounts.keith },
      { ref: 'PARTYWITHMICHAEL', count: currCounts.michael },
      { ref: 'PARTYWITHROSSI', count: currCounts.rossi },
      { ref: 'PARTYINNYC', count: currCounts.nyc },
      { ref: 'PARTYWITHCRANIUMS', count: currCounts.craniums },
      { ref: 'PARTYWITHALEX', count: currCounts.alex },
      { ref: 'PARTYWITHPLAYBOY', count: currCounts.playboy },
      { ref: 'PARTYWITHKARSEN', count: currCounts.karsen },
      { ref: 'PARTYWITHZ', count: currCounts.z },
      { ref: 'PARTYFOX122', count: currCounts.fox },
      { ref: 'WHATSTHEPASSWORD?', count: currCounts.password },
      { ref: 'PARTYWITHMATT', count: currCounts.matt },
      { ref: 'NOLIMITS', count: currCounts.nolimits },
      { ref: 'IMINTHEHOUSE', count: currCounts.house },
      { ref: 'ARTBASEL2021', count: currCounts.artbasel },
      { ref: 'PARTYBASEL2021', count: currCounts.basel },
      { ref: 'PARTYMIAMI2021', count: currCounts.miami },
      { ref: 'PARTYWITHZHOVAK', count: currCounts.zhovak },
      { ref: 'SWANSKNOWHOW2PARTY', count: currCounts.swans },
      { ref: 'HOLDMYBEER', count: currCounts.beer },
      { ref: 'OVERPRICEDJPEGS', count: currCounts.jpeg },
      { ref: 'MIDNIGHT', count: currCounts.midnight },
      { ref: 'NOSLEEP', count: currCounts.nosleep },
      { ref: 'BACK2BACK', count: currCounts.back2back },
      { ref: 'ANIMALZ2021', count: currCounts.animalz },
    ];
    let emailSlice = email.slice(0, email.indexOf('@'));
    let ref = refcode.toUpperCase();
    if (
      currentRefs.find((x: any) => {
        console.log('find info: ', x);
        if (x.ref === ref && x.count < 500) {
          setCurrCounts(x.count + 1);
          console.log(x);
          return true;
        }
      })
    ) {
      await setName(emailSlice);
      await setReferralCode(ref);
      await setLocalData(ref);
      await handleData(ref);
    } else {
      console.log(ref);
      alert('Either the referral code is invalid or it has been used too many times!');
    }
  };

  const handlePassword = (e: any) => {
    if (e === 'onload') {
      return;
    } else if (storedPassword === referralCode && password === storedPassword) {
      setAuthenticated(true);
      setAuth(true);
      return;
    }
    e.preventDefault();
    console.log('Try your referral code ;)');
  };

  useEffect(() => {
    clearLocalData();
    handlePassword('onload');
  }, []);

  useEffect(() => {
    if (localStorage.getItem(AUTHENTICATED_COOKIE_KEY_2)) {
      setAuthenticated(true);
      setAuth(true);
      cookies.set(AUTHENTICATED_COOKIE_KEY_2, localStorage.getItem(AUTHENTICATED_COOKIE_KEY_2), {
        path: '/',
        maxAge: 60 * 60 * 24 * 365,
      });
    }
  }, [authenticated]);

  return (
    <Layout>
      <SEO />
      {authenticated && auth ? (
        <HomeWrapper>
          <One />
          <Two />
          <Three />
          <Four />
          <Five />
          <Six />
          <Seven />
          <Eight />
        </HomeWrapper>
      ) : (
        <Wrapper>
          <Inner>
            <img
              style={{ height: '100%', width: '100%', minWidth: '100%' }}
              src={brandHeroLogo}
              alt="logo"
            />
            <H2>{!submitClicked ? '' : "You're on the presale list!"}</H2>
            {!submitClicked ? <MiniLine></MiniLine> : <div></div>}
            {!submitClicked ? (
              <Form>
                <FormInner>
                  <label></label>
                  <Input4
                    type="text"
                    id="refcode"
                    placeholder="EMAIL"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input4>
                  <Input4
                    type="text"
                    id="refcode"
                    placeholder="PASSCODE"
                    value={refcode}
                    onChange={(e) => setRefcode(e.target.value)}
                  ></Input4>
                  {/*<Msg style={{ marginTop: 0, color: '#010101', textAlign: 'center' }}>
                    ENTER TO ADD YOUR EMAIL TO THE PRESALE LIST
                  </Msg>*/}
                  <RefBtn id="submit" onClick={(e) => handleSetRefcode(e)}>
                    ENTER
                  </RefBtn>
                </FormInner>
              </Form>
            ) : (
              <Inner>
                <Msg style={{ textAlign: 'center' }}>
                  Welcome to the party!!!
                  <br />
                </Msg>
              </Inner>
            )}
          </Inner>
        </Wrapper>
      )}
    </Layout>
  );
};

const MiniLine = styled.div`
  display: block;
  position: fixed;
  margin-top: -26px;
  width: 2px;
  height: 70px;
  background-color: #d19459;
  ${media.tablet} {
    display: none;
    margin-top: 70px;
  }
`;
const Msg = styled.div`
  margin-top: 30px;
  width: 80%;
  color: #fff;
`;
const HomeWrapper = styled.div`
  overflow: clip;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  background-image: url(${crumplebgDesktop});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  max-height: 100vh;
  overflow: clip;
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.tablet} {
    background-image: url(${crumplebgMobile});
    background-position: top;
  }
`;
const Inner = styled.div`
  min-width: 400px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin-bottom: -60px;
  }
  ${media.tablet} {
    img {
      margin-bottom: -20px;
    }
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #060707;
  margin-bottom: 15%;
  width: 600px;
  ${media.tablet} {
    margin-bottom: 5%;
    width: 300px;
  }
`;
const FormInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #f2eae1;
  margin: 15px;
  padding: 65px 35px 55px 35px;
  width: 570px;
  ${media.tablet} {
    width: 270px;
  }
`;
const H2 = styled.h2`
  background: linear-gradient(100deg, #b29352 10%, #d9c9a9 50%, #b29352 90%);
  background-clip: text;
  text-fill-color: transparent;
  background: -webkit-linear-gradient(100deg, #b29352 10%, #d9c9a9 50%, #b29352 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5rem;
`;
const Input4 = styled.input`
  color: #101010;
  width: 100%;
  font-size: 0.75rem;
  margin-top: 0rem;
  margin-bottom: 2.5rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #000000;
  border-radius: 0;
  background: transparent;
  outline: none;
`;
const RefBtn = styled.button`
  color: #101010;
  background: transparent;
  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: block;
  height: 40px;
  width: 200px;
  border-radius: 50%;
  border: 2px solid #f93f06;
  font-size: 0.9rem;
  transition: all 250ms linear;
  :hover {
    color: #f93f06;
    transition: all 150ms linear;
  }
  :active {
    transform: translateY(2px);
    transition: all 150ms linear;
  }
  :visted {
    transform: translateY(2px);
    transition: all 150ms linear;
  }
`;

export const pageQuery = graphql`
  query {
    allCounts {
      nodes {
        gabe
        keith
        michael
        rossi
        nyc
        craniums
        alex
        playboy
        karsen
        z
        fox
        password
        matt
        nolimits
        house
        artbasel
        basel
        miami
        zhovak
        swans
        beer
        jpeg
        midnight
        nosleep
        back2back
        animalz
      }
    }
  }
`;

export default IndexPage;
