import { COUNTS_COLLECTION_NAME } from './consts';
import faunadb from 'faunadb';
const q: any = faunadb.query;
const clientSecret: any = process.env.GATSBY_FAUNA_KEY;
let updateCounts: Function = () => {};

interface QueryCountsResponse {
  data: Counts;
  error: any;
  ts: any;
  id: string;
}

interface Counts {
  gabe: number;
  keith: number;
  michael: number;
  rossi: number;
  nyc: number;
  craniums: number;
  alex: number;
  playboy: number;
  karsen: number;
  z: number;
  fox: number;
  password: number;
  matt: number;
  nolimits: number;
  house: number;
  artbasel: number;
  basel: number;
  miami: number;
  zhovak: number;
  swans: number;
  beer: number;
  jpeg: number;
  midnight: number;
  nosleep: number;
  back2back: number;
  animalz: number;
}

if (typeof window !== `undefined`) {
  const client = new faunadb.Client({
    secret: clientSecret,
  });

  updateCounts = async (countsObj: Counts) => {
    try {
      const queryResponse: QueryCountsResponse = await client.query(
        q.Create(q.Collection(COUNTS_COLLECTION_NAME), {
          data: {
            gabe: countsObj?.gabe,
            keith: countsObj?.keith,
            michael: countsObj?.michael,
            rossi: countsObj?.rossi,
            nyc: countsObj?.nyc,
            craniums: countsObj?.craniums,
            alex: countsObj?.alex,
            playboy: countsObj?.playboy,
            karsen: countsObj?.karsen,
            z: countsObj?.z,
            fox: countsObj?.fox,
            password: countsObj?.password,
            matt: countsObj?.matt,
            nolimits: countsObj?.nolimits,
            house: countsObj?.house,
            artbasel: countsObj?.artbasel,
            basel: countsObj?.basel,
            miami: countsObj?.miami,
            zhovak: countsObj?.zhovak,
            swans: countsObj?.swans,
            beer: countsObj?.beer,
            jpeg: countsObj?.jpeg,
            midnight: countsObj?.midnight,
            nosleep: countsObj?.nosleep,
            back2back: countsObj?.back2back,
            animalz: countsObj?.animalz,
          },
        })
      );
      const countsInfo = {
        gabe: queryResponse?.data.gabe,
        keith: queryResponse?.data.keith,
        michael: queryResponse?.data.michael,
        rossi: queryResponse?.data.rossi,
        nyc: queryResponse?.data.nyc,
        craniums: queryResponse?.data.craniums,
        alex: queryResponse?.data.alex,
        playboy: queryResponse?.data.playboy,
        karsen: queryResponse?.data.karsen,
        z: queryResponse?.data.z,
        fox: queryResponse?.data.fox,
        password: queryResponse?.data.password,
        matt: queryResponse?.data.matt,
        nolimits: queryResponse?.data.nolimits,
        house: queryResponse?.data.house,
        artbasel: queryResponse?.data.artbasel,
        basel: queryResponse?.data.basel,
        miami: queryResponse?.data.miami,
        zhovak: queryResponse?.data.zhovak,
        swans: queryResponse?.data.swans,
        beer: queryResponse?.data.beer,
        jpeg: queryResponse?.data.jpeg,
        midnight: queryResponse?.data.midnight,
        nosleep: queryResponse?.data.nosleep,
        back2back: queryResponse?.data.back2back,
        animalz: queryResponse?.data.animalz,
        _ts: queryResponse?.ts,
        _id: queryResponse?.id,
      };
      return countsInfo;
    } catch (err) {
      console.log(err);
    }
  };
}

export default updateCounts;
